$main-height: calc(100vh - 6rem);

.base {
  position: relative;
  height: $main-height;
  min-height: $main-height;
  max-height: $main-height;
  width: 100%;

  .headar {
    position: relative;
    width: 100%;
  }
  .footer {
    position: relative;
    bottom: 0;
    padding: 0.5rem;
    padding-top: 1rem;
    width: 100%;
  }

  .body {
    position: relative;
    overflow-y: auto;
    width: 100%;
  }
}
