.box {
  padding: 1rem 5rem;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f1f1f1;

  .reserve {
    color: #00000088;
  }

  .empty {
    color: #00000088;
  }
}
