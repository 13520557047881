$main-height: calc(100vh - 5rem);

.base {
  position: relative;
  overflow-y: auto;
  height: $main-height;
  min-height: $main-height;
  max-height: $main-height;
  width: 100%;
}
