.nomal-remark {
  display: block; /* 通常の表示 */
}
.print-remark {
  width: 0;
  height: 0;
  overflow: hidden; /* 印刷時に非表示 */
}
@media print {
  .nomal-remark {
    display: none;
  }
  .print-remark {
    width: auto;
    height: fit-content;
    overflow: visible; /* 印刷時に表示する */
    white-space: pre;
  }
}
