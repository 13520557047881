@import '../../../styles/screen';

.dialog-content {
  position: relative;
  min-width: 16rem;
  min-height: fit-content;

  @include mq(sm) {
    min-width: 34rem;
    min-height: 16rem;
  }
}
